import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function TechVeteranRobWoodbridge () {
  const shareTitle = 'Tech veteran Rob Woodbridge joins Trexity'
  const articleLink = 'https://www.obj.ca/article/techopia/shifting-gears-tech-veteran-rob-woodbridge-moves-c-suite-delivery-platform-trexity'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Shifting gears: Tech veteran Rob Woodbridge moves into C-suite at delivery platform Trexity</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>November 13, 2020</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/rob-woodbridge.jpg" alt="Rob Woodbridge" />
                <div className="snippet">The following preview is from the <a href={articleLink} target="_blank" rel="noreferrer">Ottawa Business Journal</a></div>
                <p>Alok Ahuja thinks he’s found the perfect co-pilot to help guide him down the path to success in the ultra-competitive last-mile delivery business.</p>
                <p>As his fledgling shipping platform, Trexity, was ramping up this spring in response to the pandemic-fuelled explosion in e-commerce, the Ottawa entrepreneur knew he needed someone at his side to steady the ship as he entered the rocky waters of scaling a startup. </p>
                <p>At the same time, Rob Woodbridge – a fixture of the local business community who’d spent the past two years in charge of ride-hailing service Lyft’s local operations – was starting to sense there were new opportunities emerging in the delivery space. </p>
                <a href={articleLink} target="_blank" rel="noreferrer">Read full article on Ottawa Business Journal</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ottawa-company-delivers-help" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Ottawa company delivers help to businesses with same-day drop-off</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
